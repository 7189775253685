import React from "react";
import { Slide, Fade } from "react-awesome-reveal";
import serimg from "../img/bg2.png";
import ser5 from "../img/bg3.png";
import Review from "../Components/Reviews";


function importImg(r){
    return r.keys().map(r);
}

export default function Home(){

    const fnames = importImg(require.context('../img/nails/' , false , /\.(png|jpg|jpeg)$/));

    const DataHome = [
        {
            id: 1,
            name: 'Manicure',
            description: 'Experience the ultimate hand pampering with our professional manicure services. From shaping and buffing to a perfect polish, achieve stunning nails that reflect your personal style.',
            img: require("../img/Services/5.png"),
        },
        {
            id: 2,
            name: 'Pedicure',
            description: 'Indulge in a relaxing pedicure session that not only beautifies your feet but also soothes and rejuvenates. Our expert care ensures soft, smooth skin and impeccably polished toes.',
            img: require("../img/Services/3.png"),
        },
        {
            id: 3,
            name: 'Eye Lash',
            description: 'Enhance your eyes with our eyelash extension services. Customizable to your preference, achieve a natural or dramatic look with fuller, longer lashes that brighten and enlarge your eyes.',
            img: require("../img/Services/4.png"),
        },
        {
            id: 4,
            name: 'Custom Design',
            description: 'Unleash your creativity with our custom design services. Tailored to your unique vision, we create personalized nail art and beauty enhancements that stand out and express your individuality.',
            img: require("../img/Services/1.png"),
        },
        {
            id: 5,
            name: 'Gel-X',
            description: 'Transform your nails with Gel-X extensions, the latest in nail innovation. Enjoy durable, lightweight, and natural-looking nails tailored to your desired length and shape for weeks.',
            img: require("../img/Services/2.png"),
        },
    ]

    return(
        
        <main>
            <section className="w-screen lg:bg-bg1 bg-no-repeat bg-bg1-1 bg-center bg-cover h-screen relative">
                <div className="w-screen absolute lg:right-56 text-center top-1/3 px-3 py-5 space-y-5 lg:py-8 md:space-y-6">
                    <div className="space-y-2 !font-Playfair">
                        <h1 className="text-6xl italic capitalize ml-4 text-main-color md:text-8xl tracking-widest">
                            Tip To Toe
                        </h1>
                        <h1 className="text-4xl capitalize ml-4  md:text-7xl tracking-widest italic text-main-color">Nails</h1>
                    </div>
                    <div className="p-2">
                        <div className="">
                            <ul className=" text-black space-y-2 capitalize">
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide">Stay Glamorous, Stay Confident.</h2>
                                </li>
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide italic">Luxe Lash & Nail Care in Plano, TX</h2>
                                </li>
                                <li>
                                    <h2 className="text-xl md:text-2xl tracking-wide">With Exceptional Service at Unbeatable Prices.</h2>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button className="hover:bg-white/0 hover:border-[1px] rounded-lg hover:border-black transition duration-200 delay-100 md:text-xl text-white text-lg py-3 group cursor-pointer px-12 bg-main-color md:tracking-wide">
                        <a target="_blank" href="https://www.fresha.com/a/tip-to-toe-nail-plano-plano-3308-preston-road-yl2a8fyw" className = "group-hover:text-black transition duration-200 delay-100 font-extrabold">Book Appointment</a>
                    </button>
                </div>
            </section>

            <section>
                <div className="pt-12 pb-6 lg:pt-24 lg:pb-24 space-y-4">
                    <div className="lg:p-4 p-2 space-y-10 lg:space-y-12 mx-auto max-w-screen-xl">
                        <div className=" flex items-center space-y-5 lg:space-y-8 flex-col">
                            <div className=" text-[30px] lg:text-4xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-[#4A4A4A] font-Playfair">
                                    About Us
                                </h2>
                            </div>
                            <div className="text-center flex justify-center tracking-wide">
                                <div className=" lg:w-4/5">
                                    <span className=" lg:text-lg text-[#757575]">
                                        Welcome to Tip To Toe Nails, the go-to nail salon in Plano, TX, specializing in custom nail 
                                        designs, Gel-X extensions, and lash services. Our expert team delivers top beauty 
                                        trends with a focus on quality and safety, ensuring every service is personalized to your needs. 
                                        Indulge in exceptional care and experience beauty perfection at Tip To Toe Nails.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-3 mx-4 justify-items-center gap-8 cursor-pointer">
                            <Slide direction="left" delay={50}>
                                {DataHome.map((data) => (
                                    <div key={data.id} className=" flex flex-col space-y-3">
                                        <div className=" rounded-lg">
                                            <img className=" rounded-lg" src={data.img} />
                                        </div>
                                        <div className=" text-2xl font-Playfair font-bold">
                                            <span>{data.name}</span>
                                        </div>
                                        <div className=" max-w-md text-md">
                                            <span>{data.description}</span>
                                        </div>
                                    </div>
                                ))}
                            </Slide>
                        </div>

                        <div className=" flex justify-center">
                            <button onClick={() => window.location.href = '/Services'} className=" py-3 transition ease-in-out duration-200 delay-100 group border-main-color cursor-pointer hover:bg-main-color px-12 border-[1px]">
                                <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                    View More
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className=" p-5 pt-6 pb-8  lg:pt-24 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade delay={50}>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Discover Excellence</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-Playfair text-black">
                                            Expert pampering and luxurious
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Treatments
                                        </span>
                                    </div>
                                    <span className=" text-lg text-black">
                                        Experience expert pampering and luxurious treatments at Lash Nail Bar, Plano’s premier salon. Offering top-tier beauty services in a tranquil setting, we ensure each visit leaves you refreshed and radiant.
                                    </span>
                                    <div className=" ">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className=" font-bold  font-Playfair italic">
                                                        Expertise and Precision
                                                    </span>
                                                </div>
                                                <span className=" text-[#4A4A4A] ml-8 text-md">
                                                    Our highly skilled technicians deliver exceptional nail care with a commitment to perfection.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className="font-bold font-Playfair italic">
                                                        Luxurious Pampering
                                                    </span>
                                                </div>
                                                <span className=" text-[#4A4A4A] ml-8 text-md">
                                                    Indulge in a serene escape from the daily grind with relaxing, luxurious treatments designed to rejuvenate your spirit and enhance your natural beauty.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className="font-bold font-Playfair italic">
                                                        Personalized Service
                                                    </span>
                                                </div>
                                                <span className=" text-[#4A4A4A] ml-8 text-md ">
                                                    Our tailored treatments are crafted to meet your unique needs in a calm, inviting atmosphere, leaving you feeling pampered and completely satisfied.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden rounded-xl flex">
                                <img className=" rounded-xl" alt="da" src={serimg} />
                            </div>
                            <div className="hidden rounded-xl lg:block lg:bg-bg2 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Reviews" className="">
                <div className="pt-6 pb-12 lg:pt-12 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 lg:mx-auto mx-4 max-w-screen-xl">
                        <div className=" flex lg:space-y-8 space-y-5 flex-col">
                            <div className=" lg:text-4xl text-[30px] flex flex-col text-center items-center tracking-wide space-y-[1px] lg:space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" font-Playfair">
                                    Customer Reviews
                                </h2>
                            </div>
                            <div>
                                <Review />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="pt-6 pb-12 lg:pt-8 space-y-4">
                    <div className="lg:p-4 p-2 space-y-12 lg:mx-auto mx-2 max-w-screen-xl">
                        <div className=" flex flex-col items-center space-y-4 lg:space-y-6">
                            <div className=" text-[30px] lg:text-4xl tracking-wide space-y-2">
                                <div className=" border-[1px] border-main-color w-16"></div>
                                <h2 className=" text-[#4A4A4A] font-Playfair">
                                    Our Gallery
                                </h2>
                            </div>
                            <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 justify-items-center p-4 cursor-pointer">
                                {fnames.slice(0,6).map((fname , index) =>(
                                    <div key={index} className="shadow-lg overflow-hidden rounded-lg">
                                        <img className="w-full ease-in-out transition-all duration-1000 hover:scale-125" src={fname} alt={fname}/>
                                    </div>
                                ))}
                            </div>
                            <div className=" flex justify-center">
                                <button onClick={() => window.location.href = '/Gallery'} className=" py-3 transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color px-12 border-[1px]">
                                    <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                        View More
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid lg:justify-items-center gap-8 lg:grid-cols-2">
                            <div className="flex flex-col lg:justify-center space-y-5">
                                <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-black/40">Contact Us</span>
                                <div className=" flex flex-col space-y-4">
                                    <span className=" text-4xl font-Playfair text-black">
                                        Reach out for personalized beauty
                                    </span>
                                    <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                        Solutions
                                    </span>
                                </div>
                                <span className=" text-lg text-black">
                                    Effortless Connections for Personalized Beauty Solutions
                                </span>
                                <div className="">
                                    <ul className="lg:space-y-6 space-y-4 text-lg lg:text-xl">
                                        <li>
                                            <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:9729644704">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="lg:h-12 lg:w-12 w-8 h-8 shrink-0 text-main-color"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                                    />
                                                </svg>
                                                <span className="flex-1 text-black">(972) 964-4704</span>
                                            </a>
                                        </li>
                                        <li className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="lg:h-12 lg:w-12 w-8 h-8 shrink-0 text-main-color"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                                />
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                                />
                                            </svg>
                                            <a target="_blank" rel="noreferrer" href="https://maps.app.goo.gl/N2gEyfKgCA3hXVHeA" className="-mt-0.5 flex-1 not-italic text-black">
                                                3308 Preston Rd #315, Plano, TX 75093, United States
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex p-4 lg:w-[480px] border-random lg:h-[550px]">
                                <img className=" lg:w-full hidden lg:block border-random lg:h-full rounded-full" alt="da" src={ser5} />
                                <img className=" lg:w-full lg:hidden block lg:h-full" alt="da" src={ser5} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>

    )
}