import {React , useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faYelp} from "@fortawesome/free-brands-svg-icons";


function FloatBtn(){
    const Data = [faFacebookF , faInstagram , faYelp]
    const [isThere , SetisThere] = useState(false);
    
    const changeisThere = () =>{
        if ( (window.pageYOffset > 300) || (window.pageYOffset > window.screen.height - 5) ){
            SetisThere(true)
        }
        else{
            SetisThere(false)
        }
    }

    // const scrollTop = () => {
    //     window.scrollTo({
    //         top:0,
    //         behavior:"smooth",
    //     })
    // }

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <main>
            <div className= {`${isThere?"opacity-100": "opacity-0"} flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-3 lg:right-8`}>
                {Data.map((s,index) => (
                    <div key={index} className= "rounded-full flex cursor-pointer justify-center items-center p-2">
                        
                        {
                            index === 0 && (
                                <button onClick={() => window.location.href = 'https://www.facebook.com/tiptotoenailsplano?paipv=0&eav=Afb9WNLen9ZTIRVHhG5U0D-RLilBbnCYXEWu3ktGEG_TW4uuUqvSU-chaBCvYnpR_t0&_rdr'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-sky-400" />
                                </button>
                            )

                        }

                            {
                            index === 1 && (
                                <button onClick={() => window.location.href = 'https://www.instagram.com/tiptotoenailsspa'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-pink-400" />
                                </button>
                            )

                        }

                        { 
                            index === 2 && (
                                <button onClick={() => window.location.href = 'https://www.yelp.com/biz/tip-to-toe-nails-plano'} className="" type="button">
                                    <FontAwesomeIcon icon={s} className="w-6 h-6 lg:w-8 lg:h-8 text-rose-400" />
                                </button>
                            )

                        }
                    </div>
                ))}
            </div>
        </main>
    );
};

export default FloatBtn;